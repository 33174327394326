import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { AuthService } from '../../shared/auth.service';
import { Compte, Client } from '../../shared/model';
import { ResponsePolicy } from '../../shared/STATUS';
import { Router } from '@angular/router';

declare var showLoader;

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.css']
})
export class SelectAccountComponent implements OnInit {
   cpts: Array<Compte>;
   usr: Client;
  constructor(private _ds: DataService, private _as: AuthService, private router: Router) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.cpts = new Array<Compte>();
    this.usr = this._as.getUser();
    this.getComptes();
  }

  getComptes(): void {
    showLoader(true);
    this._ds.GetClientAccounts(this.usr.Numcli, this.usr.Codage).subscribe((res) => {
      showLoader(false);
      if (res != null) {
        this.cpts = res.filter((c) =>
        c.Idplan[0] !== '9' && c.Typcpt.toLowerCase() !== 'i'
        && c.Typcpt.toLowerCase() !== 'e' && c.Cdetat.toLowerCase() !== '09');
      }
    });
  }

  onAccountSelected(cpt: Compte): void {
    this._as.setAccount(cpt);
    this.router.navigate(['/auth/dashboard']);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

}
