import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { AccountsRouterModule } from './accounts-router.module';
import { CheckbookRequestComponent } from './checkbook-request/checkbook-request.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ETransfertComponent } from './e-transfert/e-transfert.component';
import { PINComponent } from './pin/pin.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TransfertOrderComponent } from './transfert-order/transfert-order.component';
import { LoginComponent } from './login/login.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { GateWayAccountComponent } from './gate-way-account/gate-way-account.component';
import { GateWayPINComponent } from './gate-way-pin/gate-way-pin.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { UpdateUsernameComponent } from './update-username/update-username.component';
import { WayInOneComponent } from './way-in-one/way-in-one.component';
import { WayInTwoComponent } from './way-in-two/way-in-two.component';



@NgModule({
  declarations: [
    AccountBalanceComponent,
    ChangePassComponent,
    CheckbookRequestComponent,
    DashboardComponent,
    ETransfertComponent,
    GateWayAccountComponent,
    GateWayPINComponent,
    LandingPageComponent,
    LoginComponent,
    PINComponent,
    SelectAccountComponent,
    TransactionHistoryComponent,
    TransfertOrderComponent,
    UpdateUsernameComponent,
    WayInOneComponent,
    WayInTwoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AccountsRouterModule
  ],
  exports: [
  ]
})
export class AccountsModule { }
