import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { NgxSoapService, Client, ISoapMethodResponse } from 'ngx-soap';
import { SOAPClientParameters } from './SOAPClientParameters';
import { SoapHttp } from './SoapHttp.service';
import { TrameUtilService } from './trame-util.service';

@Injectable({
  providedIn: 'root'
})
export class SoapService {

  // public baseUrl = '/AlphaWebServiceBank/AlphaWebBanque.asmx';
  // public baseUrl = '/AlphaWebService/AlphaWebBanque.asmx';
  // public baseUrl = 'http://164.68.123.104/AlphaWebService/AlphaWebBanque.asmx';
  // public baseUrl = 'http://167.86.113.251/AlphaWebServiceBank/AlphaWebBanque.asmx';
  // public baseUrl = 'http://192.168.0.101/AlphaWebService/AlphaWebBanque.asmx';


  public baseUrl = '/AlphaWebService/AlphaWebMobileBanking.asmx';
  // public baseUrl ='http://192.168.0.101/AlphaWebService/AlphaBankSoftwareWS.asmx';
  // public baseUrl ='http://173.212.236.185/AlphaWebService/AlphaBankSoftwareWS.asmx';
  // public baseUrl ='http://144.91.82.232/AlphaWebService/AlphaBankSoftwareWS.asmx';
  // public baseUrl = 'http://164.68.123.104/AlphaWebService/AlphaBankSoftwareWS.asmx';
  // public baseUrl = 'http://207.180.213.121/AlphaWebService/AlphaBankSoftwareWS.asmx';

  constructor(private httpSoapClient: SoapHttp,
    private trameUtil: TrameUtilService) { }

  public post<T>(action: string, params: SOAPClientParameters, keepClean: boolean = false): Observable<T> {
    console.log(params);
    return this.httpSoapClient
      .invoke(this.baseUrl, action, params)
      .pipe(map((val) => {
        // console.log(val);
        if (keepClean) {
          try {
            return JSON.parse(val) as T;
          } catch (error) {
            return val;
          }
        } else {
          return this.trameUtil.GetResponse<T>(val);
        }
      }));
  }
}

