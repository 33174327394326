import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AccountsModule } from './features/accounts/accounts.module';
import { AuthGuard } from './features/shared/auth.guard';
import { DataService } from './features/shared/data.service';
import { EncrDecrService } from './features/shared/EncrDecrService';
import { SOAPClient } from './features/shared/soapclient';
import { SoapHttp } from './features/shared/SoapHttp.service';
import { RoutingModule } from './router.module';
import { ReqInterceptorService } from './features/shared/req-interceptor.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AccountsModule,
    RoutingModule
  ],
  providers: [
    DataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReqInterceptorService,
      multi: true
    },
    SoapHttp,
    SOAPClient,
    EncrDecrService,
    AuthGuard,
    { provide: 'APP_VERSION', useValue: '1.0' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
