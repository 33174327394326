import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { AuthService } from '../../shared/auth.service';
import { UpdatePasswordReqType } from '../../shared/model';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';
declare var showLoader;

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.css']
})
export class ChangePassComponent implements OnInit {
  oldPas: string;
  newPass: string;
  repeatPass: string;
  req: UpdatePasswordReqType;

  constructor(private _ds: DataService, private _as: AuthService, private _router: Router) {
    this.req = {} as UpdatePasswordReqType;
  }

  ngOnInit() {
  }

  updatePass(): void {
    showLoader(true);
    const usr = this._as.getUser();
    this.req.UserName = usr.Username;
    this.req.NumCli = usr.Numcli;
    this.req.OldPassword = Md5.hashStr(this.oldPas).toString().toUpperCase();
    this.req.NewPassword = Md5.hashStr(this.newPass).toString().toUpperCase();

    this._ds.UpdatePassword(this.req).subscribe(res => {
      showLoader(false);
      if (res != null  && res) {
        const secret = this._as.getMifi().secret;
        this._as.clearTokens();
        this._router.navigate(['/unauth/login'], { queryParams: { secret: secret }});
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    });
  }

}
