import { SOAPClientParameters } from './SOAPClientParameters';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class SoapHttp {
	private static SOAPClient_cacheWsdl = new Array<string>();
	static progid: any;

	constructor(private http: HttpClient) {
	}

	/**
	 * 
	 * @param {string} url
	 * @param {string} method 
	 * @param {SOAPClientParameters} parameters 
	 * @param {boolean} async 
	 * @param {} callback 
	 */
	invoke(url: string, method: string, parameters: SOAPClientParameters): Observable<any> {
		return from(this._loadWsdl(url, method, parameters));
	}

	// private: invoke async
	private _loadWsdl(url: string, method: string, parameters: SOAPClientParameters): Promise<any> {
		// load from cache?
		var wsdl = SoapHttp.SOAPClient_cacheWsdl[url];
		if (wsdl + "" != "" && wsdl + "" != "undefined")
			return this._sendSoapRequest(url, method, parameters, wsdl);
		// get wsdl
		return this._onLoadWsdl(url, method, parameters);
	}
	private _onLoadWsdl(url: string, method: string, parameters: SOAPClientParameters): Promise<any> {
		return this.http.get(url + '?wsdl', { responseType: "text" }).toPromise().then(wsdl => {
			let w = this._xmlDocParser(wsdl)
			// console.log(w);
			SoapHttp.SOAPClient_cacheWsdl[url] = w;	// save a copy in cache
			return this._sendSoapRequest(url, method, parameters, w);
		});
	}
	private _sendSoapRequest(url: string, method: string, parameters: SOAPClientParameters, wsdl: XMLDocument): Promise<any> {
		// get namespace
		var ns = (wsdl.documentElement.attributes["targetNamespace"] + "" == "undefined") ?
			wsdl.documentElement.attributes.getNamedItem("targetNamespace").nodeValue :
			wsdl.documentElement.attributes["targetNamespace"].value;
		// build SOAP request
		var sr =
			"<?xml version=\"1.0\" encoding=\"utf-8\"?>" +
			"<soap:Envelope " +
			"xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" " +
			"xmlns:xsd=\"http://www.w3.org/2001/XMLSchema\" " +
			"xmlns:soap=\"http://schemas.xmlsoap.org/soap/envelope/\">" +
			"<soap:Body>" +
			"<" + method + " xmlns=\"" + ns + "\">" +
			parameters.toXml() +
			"</" + method + "></soap:Body></soap:Envelope>";
		// send request
		let soapaction = ((ns.lastIndexOf("/") != ns.length - 1) ? ns + "/" : ns) + encodeURIComponent(method);
		// console.log(sr);
		return this.http.post(url, sr, {
			headers:
				{ 'Content-Type': ['text/xml; charset=utf-8', 'application/json; charset=utf-8'] },
			responseType: "text"
		}).toPromise().then(res => {
			// console.log(this._xmlDocParser(res));
			return this._treatReseult(wsdl, method, this._xmlDocParser(res));
		});
	}

	private _treatReseult(wsdl: XMLDocument, method: string, res: XMLDocument) {
		let o = null;
		let nd = this._getElementsByTagName(res, method + "Result");
		if (nd.length == 0)
			nd = this._getElementsByTagName(res, "return");	// PHP web Service?
		if (nd.length == 0) {
			if (res.getElementsByTagName("faultcode").length > 0) {
				throw new Error("STATUS: 500 \r\n" + res.getElementsByTagName("faultstring")[0].childNodes[0].nodeValue);
			}
		}
		else {
			// console.log(nd);
			o = this._soapresult2object(nd[0], wsdl);
		}
		return o;
	}
	private _soapresult2object(node, wsdl) {
		var wsdlTypes = this._getTypesFromWsdl(wsdl);
		return this._node2object(node, wsdlTypes);
	}
	private _node2object(node, wsdlTypes) {
		// null node
		if (node == null)
			return null;
		// text node
		if (node.nodeType == 3 || node.nodeType == 4)
			return this._extractValue(node, wsdlTypes);
		// leaf node
		if (node.childNodes.length == 1 && (node.childNodes[0].nodeType == 3 || node.childNodes[0].nodeType == 4))
			return this._node2object(node.childNodes[0], wsdlTypes);
		var isarray = this._getTypeFromWsdl(node.nodeName, wsdlTypes).toLowerCase().indexOf("arrayof") != -1;
		// object node
		if (!isarray) {
			var obj = null;
			if (node.hasChildNodes())
				obj = new Object();
			for (var i = 0; i < node.childNodes.length; i++) {
				var p = this._node2object(node.childNodes[i], wsdlTypes);
				obj[node.childNodes[i].nodeName] = p;
			}
			return obj;
		}
		// list node
		else {
			// create node ref
			var l = new Array();
			for (var i = 0; i < node.childNodes.length; i++)
				l[l.length] = this._node2object(node.childNodes[i], wsdlTypes);
			return l;
		}
		return null;
	}
	private _extractValue(node, wsdlTypes) {
		var value = node.nodeValue;
		switch (this._getTypeFromWsdl(node.parentNode.nodeName, wsdlTypes).toLowerCase()) {
			default:
			case "s:string":
				return (value != null) ? value + "" : "";
			case "s:boolean":
				return value + "" == "true";
			case "s:int":
			case "s:long":
				return (value != null) ? parseInt(value + "", 10) : 0;
			case "s:double":
				return (value != null) ? parseFloat(value + "") : 0;
			case "s:datetime":
				if (value == null)
					return null;
				else {
					value = value + "";
					value = value.substring(0, (value.lastIndexOf(".") == -1 ? value.length : value.lastIndexOf(".")));
					value = value.replace(/T/gi, " ");
					value = value.replace(/-/gi, "/");
					var d = new Date();
					d.setTime(Date.parse(value));
					return d;
				}
		}
	}
	private _getTypesFromWsdl(wsdl) {
		var wsdlTypes = new Array();
		// IE
		var ell = wsdl.getElementsByTagName("s:element");
		var useNamedItem = true;
		// MOZ
		if (ell.length == 0) {
			ell = wsdl.getElementsByTagName("element");
			useNamedItem = false;
		}
		for (var i = 0; i < ell.length; i++) {
			if (useNamedItem) {
				if (ell[i].attributes.getNamedItem("name") != null && ell[i].attributes.getNamedItem("type") != null)
					wsdlTypes[ell[i].attributes.getNamedItem("name").nodeValue] = ell[i].attributes.getNamedItem("type").nodeValue;
			}
			else {
				if (ell[i].attributes["name"] != null && ell[i].attributes["type"] != null)
					wsdlTypes[ell[i].attributes["name"].value] = ell[i].attributes["type"].value;
			}
		}
		return wsdlTypes;
	}
	private _getTypeFromWsdl(elementname, wsdlTypes) {
		var type = wsdlTypes[elementname] + "";
		return (type == "undefined") ? "" : type;
	}
	// private: utils
	private _getElementsByTagName(document, tagName) {
		try {
			// trying to get node omitting any namespaces (latest versions of MSXML.XMLDocument)
			return document.selectNodes(".//*[local-name()=\"" + tagName + "\"]");
		}
		catch (ex) { }
		// old XML parser support
		return document.getElementsByTagName(tagName);
	}

	private _toBase64(input) {
		var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
		var output = "";
		var chr1, chr2, chr3;
		var enc1, enc2, enc3, enc4;
		var i = 0;

		do {
			chr1 = input.charCodeAt(i++);
			chr2 = input.charCodeAt(i++);
			chr3 = input.charCodeAt(i++);

			enc1 = chr1 >> 2;
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			enc4 = chr3 & 63;

			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}

			output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2) +
				keyStr.charAt(enc3) + keyStr.charAt(enc4);
		} while (i < input.length);

		return output;
	}

	private _xmlDocParser(xmlString: string): XMLDocument {
		if (DOMParser) {
			return (new DOMParser()).parseFromString(xmlString, "text/xml");
		} else {
			return null;
		}
	}
}