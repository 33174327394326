import { Component, OnInit } from '@angular/core';
import { AccountHistory, HistValue, GetHistoRequestType, Client } from '../../shared/model';
import { DataService } from '../../shared/data.service';
import { AuthService } from '../../shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {
  hist: AccountHistory;
  req: GetHistoRequestType;
  private usr: Client;
  strDat: string;
  endDat: string;

  constructor(private _ds: DataService, private _as: AuthService) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.usr = this._as.getUser();
    this.hist = {} as AccountHistory;
    this.hist.Historique = new Array<HistValue>();
    this.req = {} as GetHistoRequestType;
    this.req.AgenceCible = this.usr.Codage;
    this.req.AgenceDem = this.usr.Codage;
    this.req.Compte = this._as.getAccount().Numcpt;
    this.req.Frais = 0;
    this.req.SecuEmf = '';
  }

  getAccountHist(): void {
    showLoader(true);
    const d = new Date();
    const mm = d.getMonth() + 1;
    const dd = d.getDate();
    const yy = d.getFullYear();
    const f = yy + '/' + mm + '/' + dd;

    this.req.DateDebut = this.strDat;
    this.req.DateFin = this.endDat ? this.endDat : f;

    this._ds.GetTransactionHist(this.req).subscribe(res => {
      showLoader(false);
      if (res != null) {
        const his = res as AccountHistory;
        if (his.Historique != null && his.Historique.length > 0) {
          his.Historique.forEach((h, i) => {
            if (h.Sense.toLowerCase() === 'c') {
              his.Solde = Number(his.Solde) + Number(h.Montant);
              h.Balance = his.Solde;
            } else if (h.Sense.toLowerCase() === 'd') {
              his.Solde = Number(his.Solde) - Number(h.Montant);
              h.Balance = his.Solde;
            }
          });
          this.hist = his;
        }
      } else {
        console.log(res);
      }
    }, (err) => { showLoader(false); });
  }

}
