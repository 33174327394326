import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
// declare var showLoader;

@Injectable({
  providedIn: 'root'
})
export class ReqInterceptorService implements HttpInterceptor {
  constructor(private dataService: DataService, private authService: AuthService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // showLoader(true);
    const uuid = this.authService.getToken();
    console.log(uuid);
    const authReq = req.clone({ setHeaders: {
      'UUID':  uuid + '@M:Mobile'
    } });
    // send cloned request with header to the next handler.
    return next.handle(authReq)
    .pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // showLoader(false);
        }
      }, error => {
        // showLoader(false);
        if (error) {
        }

      })
    );
  }
}
