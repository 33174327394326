import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { AccountHistory, Client, Compte } from '../../shared/model';
import { AuthService } from '../../shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  trans: AccountHistory;
  private usr: Client;
  cpt: Compte;
  balance: number;

  constructor(private _ds: DataService, private _as: AuthService) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.trans = {} as AccountHistory;
    this.cpt = this._as.getAccount();
    this.getLastTrans();
    this.getAccountBalance();
  }

  getLastTrans(): void {
    showLoader(true);
    this._ds.GetLastNTransactions(this.cpt.Numcpt, 5).subscribe(res => {
      showLoader(false);
      if (res != null) {
        this.trans = res;
      }
    });
  }

  getAccountBalance(): void {
    showLoader(true);
    const d = new Date();
    const mm = d.getMonth() + 1;
    const dd = d.getDate();
    const yy = d.getFullYear();
    const f = yy + '/' + mm + '/' + dd;
    this._ds.GetAccountBalance(this.cpt.Numcpt, f).subscribe(res => {
      showLoader(false);
      if (res != null && res !== '') {
        this.balance = Number(res);
      } else {
        this.balance = 0;
      }
    });
  }

}
