import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-way-in-one',
  templateUrl: './way-in-one.component.html',
  styleUrls: ['./way-in-one.component.css']
})
export class WayInOneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
