export class SOAPClientParameters {
	_pl: any[];
	constructor() {
		this._pl = new Array();
	}
	toXml() {
		var xml = "";
		for (var p in this._pl) {
			switch (typeof (this._pl[p])) {
				case "string":
				case "number":
				case "boolean":
				case "object":
					xml += "<" + p + ">" + this._serialize(this._pl[p]) + "</" + p + ">";
					break;
				default:
					break;
			}
		}
		return xml;
	}
	add(name, value) {
		this._pl[name] = value;
		return this;
	}
	_serialize(o) {
		var s = "";
		switch (typeof (o)) {
			case "string":
				s += o.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
				break;
			case "number":
			case "boolean":
				s += o.toString();
				break;
			case "object":
				// Date
				if(o != null && o!= undefined){
					if (o.constructor.toString().indexOf("function Date()") > -1) {
						var year = o.getFullYear().toString();
						var month = (o.getMonth() + 1).toString();
						month = (month.length == 1) ? "0" + month : month;
						var date = o.getDate().toString();
						date = (date.length == 1) ? "0" + date : date;
						var hours = o.getHours().toString();
						hours = (hours.length == 1) ? "0" + hours : hours;
						var minutes = o.getMinutes().toString();
						minutes = (minutes.length == 1) ? "0" + minutes : minutes;
						var seconds = o.getSeconds().toString();
						seconds = (seconds.length == 1) ? "0" + seconds : seconds;
						var milliseconds = o.getMilliseconds().toString();
						var tzminutes: any;
						tzminutes = Math.abs(o.getTimezoneOffset());
						var tzhours: any = 0;
						while (tzminutes >= 60) {
							tzhours++;
							tzminutes -= 60;
						}
						tzminutes = (tzminutes.toString().length == 1) ? "0" + tzminutes.toString() : tzminutes.toString();
						tzhours = (tzhours.toString().length == 1) ? "0" + tzhours.toString() : tzhours.toString();
						var timezone = ((o.getTimezoneOffset() < 0) ? "+" : "-") + tzhours + ":" + tzminutes;
						s += year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "." + milliseconds + timezone;
					}
					// Array
					else if (o.constructor.toString().indexOf("function Array()") > -1) {
						for (var p in o) {
							if (!isNaN(Number.parseInt(p))) // linear array
							{
								(/function\s+(\w*)\s*\(/ig).exec(o[p].constructor.toString());
								var type = RegExp.$1;
								switch (type) {
									case "":
										type = typeof (o[p]);
									case "String":
										type = "string";
										break;
									case "Number":
										type = "int";
										break;
									case "Boolean":
										type = "bool";
										break;
									case "Date":
										type = "DateTime";
										break;
								}
								s += "<" + type + ">" + this._serialize(o[p]) + "</" + type + ">";
							}
							else // associative array
								s += "<" + p + ">" + this._serialize(o[p]) + "</" + p + ">";
						}
					}
					// Object or custom function
					else
						for (var p in o) {
							var xmlAtrr = '';
							for (var p2 in o[p]) {
								if (p2 == "@") { // Calculus: Keyword for XML attributes within the objects
									for (var p3 in o[p][p2]) {
										xmlAtrr += ' ' + p3 + "=" + '"' + o[p][p2][p3] + '"';
									}
								}
							}
							if (p == '@') {
							}
							else {
								s += "<" + p + xmlAtrr + ">" + this._serialize(o[p]) + "</" + p + ">";
							}
						}
				}
				break;
			default:
				break; // throw new Error(500, "SOAPClientParameters: type '" + typeof(o) + "' is not supported");
		}
		return s;
	}
}
