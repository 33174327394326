import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { DataService } from '../../shared/data.service';
import { Client, Compte, Demchqmobile } from '../../shared/model';

declare var showLoader;

@Component({
  selector: 'app-checkbook-request',
  templateUrl: './checkbook-request.component.html',
  styleUrls: ['./checkbook-request.component.css']
})
export class CheckbookRequestComponent implements OnInit {

  req: Demchqmobile;
  reqs: Array<Demchqmobile>;
  private _cpt: Compte;
  private _usr: Client;

  constructor(private _ds: DataService, private _as: AuthService) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.reqs = new Array<Demchqmobile>();
    this.req = {} as Demchqmobile;
    this._cpt = this._as.getAccount();
    this._usr = this._as.getUser();
    this.req.Codage = this._usr.Codage;
    this.req.Faxcli = this._usr.Faxcli;
    this.req.Numcpt = this._cpt.Numcpt;
    this.req.Nomcpt = this._cpt.Nomcpt;
    this.req.Numcli = this._usr.Numcli;
    this.req.Telcli = this._usr.Telcli;
    this.req.Status = 'P';
    this.req.Datdem = new Date().toDateString();

    this.getChkbookReqs();
  }

  getChkbookReqs(): void {
     showLoader(true);
    this._ds.GetCheckbookReq(this._cpt.Numcpt).subscribe(res => {
      showLoader(false);
      console.log(res);
      if (res != null) {
        this.reqs = res as Array<Demchqmobile>;
      }
    });
  }

  onSendReq(): void {
    console.log(this.req);
    showLoader(true);
    this._ds.NewCheckbookReq(this.req).subscribe(res => {
      showLoader(false);
      if (res != null) {
        this.initView();
      } else {
        console.log(res);
      }
    });
  }

}
