import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { AuthService } from '../../shared/auth.service';
import { Client, TransferOrder, Compte } from '../../shared/model';

declare var showLoader;

@Component({
  selector: 'app-transfert-order',
  templateUrl: './transfert-order.component.html',
  styleUrls: ['./transfert-order.component.css']
})
export class TransfertOrderComponent implements OnInit {

  private _acc: Compte;
  trans: TransferOrder;
  numcli: string;

  constructor(private _ds: DataService, private _as: AuthService) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.trans = {} as TransferOrder;
    this._acc = this._as.getAccount();
    this.trans.Num = this._acc.Numcpt;
    this.trans.Nom = this._acc.Nomcpt;
    this.numcli = this._acc.Numcli;
  }

  onSendRequest(): void {
    showLoader(true);
    this.trans.DateOp = new Date();
    this.trans.Status = 'A';
    this.trans.TypeTrans = 'I';
    this.trans.Codage = this._acc.Agccpt;

    this._ds.NewTransferOrder(this.trans).subscribe(res => {
      showLoader(false);
      if (res != null && res instanceof String) {
        this.initView();
      } else {
        console.log(res);
      }
    });
  }

}
