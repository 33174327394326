import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-way-in-two',
  templateUrl: './way-in-two.component.html',
  styleUrls: ['./way-in-two.component.css']
})
export class WayInTwoComponent implements OnInit {

  isRoute: boolean;

  constructor(private router: Router) {
    if (this.router.url === '/auth/choose/account') {
      this.isRoute = true;
    }
  }

  ngOnInit() {
  }

}
