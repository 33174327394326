import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gate-way-pin',
  templateUrl: './gate-way-pin.component.html',
  styleUrls: ['./gate-way-pin.component.css']
})
export class GateWayPINComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
