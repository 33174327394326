import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { Client } from '../../shared/model';
import { AuthService } from '../../shared/auth.service';
import { Md5 } from 'ts-md5';

declare var showLoader;

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.css']
})
export class PINComponent implements OnInit {
  pin: number;
  param: string;
  private usr: Client;
  title: string;
  subTtle: string;

  constructor(private router: Router, private route: ActivatedRoute,
    private _ds: DataService, private _as: AuthService) {
    this.route.params.subscribe(params => {
      this.param = params.id;
    });
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.usr = this._as.getUser();
    if (this.usr != null && !this.usr.HasPIN) {
      this.title = 'Create Your 4 digit PIN';
      this.subTtle = 'You will use this PIN for all transactions';
    } else {
      this.title = 'Enter your 4 digit PIN';
      this.subTtle = 'Just to ensure it\'s you doing the transaction';
    }
  }

  onCheckPIN(): void {
    showLoader(true);
    if (this.pin !== undefined && this.pin != null && this.pin.toString().length === 4) {
      this._ds.CheckPIN(this.usr.Numcli, Md5.hashStr(this.pin.toString()).toString(), this.usr.Codage).subscribe(res => {
        showLoader(false);
        if (res != null && res) {
          if (this.param === 'transacthistory') {
            this.router.navigate(['/auth/trans-hist']);
          } else if (this.param === 'transorder') {
            this.router.navigate(['/auth/transfer-order']);
          } else if (this.param === 'updusernm') {
            this.router.navigate(['/auth/update-username']);
          } else if (this.param === 'updpass') {
            this.router.navigate(['/auth/update-pass']);
          }
        } else { console.log(res); }
      }, (err) => {
        showLoader(false);
      });
    }
  }

  onCreatePIN(): void {
    showLoader(true);
    if (this.pin !== undefined && this.pin != null && this.pin.toString().length === 4) {
      this.usr.PIN = Md5.hashStr(this.pin.toString()).toString();
      this.usr.HasPIN = true;

      this._ds.CreatePIN(this.usr).subscribe(res => {
        showLoader(false);
        if (res != null && res) {
          if (this.param === 'transacthistory') {
            this.router.navigate(['/auth/trans-hist']);
          } else if (this.param === 'transorder') {
            this.router.navigate(['/auth/transfer-order']);
          } else if (this.param === 'updusernm') {
            this.router.navigate(['/auth/update-username']);
          } else if (this.param === 'updpass') {
            this.router.navigate(['/auth/update-pass']);
          }
        } else { console.log(res); }
      }, (err) => {
        showLoader(false);
      });
    }
  }

  onChkOrCreatePIN(): void {
    if (this.usr.HasPIN) {
      this.onCheckPIN();
    } else {
      this.onCreatePIN();
    }
  }

}
