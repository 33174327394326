import { Injectable } from '@angular/core';
import { EncrDecrService } from './EncrDecrService';
import { Client, CreditUnion, Compte } from './model';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    // @Inject(LOCAL_STORAGE) private lStorage: WebStorageService,
    // @Inject(SESSION_STORAGE) private sStorage: WebStorageService,
    private crypto: EncrDecrService
  ) { }

  setIsLogged(status: boolean): void {
   sessionStorage.set('isLegal', status);
  }

  getIsLogged(): boolean {
    return sessionStorage.get('isLegal');
  }

  /**
   * Get active user from session.
   * @return Object: UserInfo
   */
  getUser(): Client {
    if (sessionStorage.getItem('syjndkdhzo') !== null && sessionStorage.getItem('syjndkdhzo') !== undefined) {
      const usr = this.crypto.decrypt(sessionStorage.getItem('syjndkdhzo'));
      return JSON.parse(usr) as Client;
    } else {
      return null;
    }

  }

  /**
   * Set active user in session.
   * @param object: UserInfo
   * @return void
   */
  setUser(user: Client): void {
    const usr = this.crypto.encrypt(JSON.stringify(user));
    sessionStorage.setItem('syjndkdhzo', usr);
  }

   /**
   * Get active CreditUnion from session.
   * @return Object: CreditUnion
   */
  getMifi(): CreditUnion {
    if (sessionStorage.getItem('ieuriwe') !== null && sessionStorage.getItem('ieuriwe') !== undefined) {
      const mif = this.crypto.decrypt(sessionStorage.getItem('ieuriwe'));
      return JSON.parse(mif) as CreditUnion;
    } else {
      return null;
    }

  }

  /**
   * Set active CreditUnion in session.
   * @param object: CreditUnion
   * @return void
   */
  setMifi(mifi: CreditUnion): void {
    const mif = this.crypto.encrypt(JSON.stringify(mifi));
    sessionStorage.setItem('ieuriwe', mif);
  }

  /**
   * Get active Account from session.
   * @return Object: Account
   */
  getAccount(): Compte {
    if (sessionStorage.getItem('ienaqldo') !== null && sessionStorage.getItem('ienaqldo') !== undefined) {
      const cpt = this.crypto.decrypt(sessionStorage.getItem('ienaqldo'));
      return JSON.parse(cpt) as Compte;
    } else {
      return null;
    }

  }

  /**
   * Set active Account in session.
   * @param object: Account
   * @return void
   */
  setAccount(cpt: Compte): void {
    const cp = this.crypto.encrypt(JSON.stringify(cpt));
    sessionStorage.setItem('ienaqldo', cp);
  }

  /**
   * Get's user token from local storage
   * @return string
   */
  getToken(): string {
    return localStorage.getItem('dhlesbj');
  }

  /**
   * Set user token in local storage
   * @return void
   */
  setToken(token: string): void {
    localStorage.setItem('dhlesbj', token);
  }

  /**
   * Removes values of hpCode, token and user from local storage
   */
  public clearTokens(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

}
