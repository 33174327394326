import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../shared/data.service';
import { AuthUser, CreditUnion } from '../../shared/model';
import { Md5 } from 'ts-md5';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/auth.service';

declare var showLoader;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: AuthUser;
  private secret: string;
  msg: string;
  constructor(private _route: ActivatedRoute, private _http: HttpClient,
    private _ds: DataService, private _as: AuthService, private _router: Router) {
  }

  ngOnInit() {
    this.user = {} as AuthUser;
    // this.user.codebanque = '00223';
    // this.secret = this._route.snapshot.queryParams['secret'] || undefined;
    // this.checkUrlParams(this.secret);
    // this.user.codebanque = '00012';
    this.user.codebanque = '00022';

  }

  checkUrlParams(param: string): void {
    if (param != null && param !== '' && param !== undefined) {
      this.getCreditUnion();
      return;
    }
    alert('You are not authorised to access this site');
  }

  getMifis(): Observable<any>  {
    return ;
  }

  getCreditUnion(): void {
    this._http.get(`https://cors-anywhere.herokuapp.com/https://itechapis.com/Docs/mifi.json`,
    { observe: 'response', responseType: 'json' })
      .subscribe((res: HttpResponse<Array<CreditUnion>>) => {
        const cus = res.body;
        const cu = cus.find(c => c.secret.toLowerCase() === this.secret.toLowerCase());
        if (cu !== undefined) {
          this.user.codebanque = cu.code;
          this._as.setMifi(cu);
        }
      });
  }

  onLogin(): void {
    showLoader(true);
    this._ds.login(this.user.username, this.user.password, this.user.codebanque).subscribe((res) => {
      showLoader(false);
      if (res.Status === 200) {
        this._as.setUser(res.Data);
        this._router.navigate(['/gate-way-acc/choose/account']);
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.msg = res.Error;
      }
    }, (err) => {
      showLoader(false);
      console.log(err);
      this.msg = 'An unexpected error has occured. Please try again later';
    });
  }

}
