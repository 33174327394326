import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/data.service';
import { AuthService } from '../../shared/auth.service';
import { Client } from '../../shared/model';
import { Router } from '@angular/router';

declare var showLoader;

@Component({
  selector: 'app-update-username',
  templateUrl: './update-username.component.html',
  styleUrls: ['./update-username.component.css']
})
export class UpdateUsernameComponent implements OnInit {
  usr: Client;
  username: string;
  mdp: string;

  constructor(private _ds: DataService, private _as: AuthService, private _router: Router) {
    this.initView();
  }

  ngOnInit() {
  }

  initView(): void {
    this.usr = this._as.getUser();
  }

  updateUsername(): void {
    showLoader(true);
    this._ds.UpdateUsername(this.username, this.usr.Numcli, this.usr.Codage).subscribe(res => {
      showLoader(false);
      if (res != null && res) {
        const secret = this._as.getMifi().secret;
        this._as.clearTokens();
        this._router.navigate(['/unauth/login'], { queryParams: { secret: secret }});
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        console.log(res);
      }
    });
  }

}
