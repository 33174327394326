import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent {

  // tslint:disable-next-line: variable-name
  private _opened: boolean = false;

  constructor(private _as: AuthService, private _router: Router) { }

  private _toggleSidebar() {
    this._opened = !this._opened;
  }

  logout(): void {
    const secret = this._as.getMifi().secret;
    this._as.clearTokens();
    this._router.navigate(['/unauth/login'], { queryParams: { secret: secret } });
    setTimeout(() => {
      window.location.reload();
    }, 100);
    console.log("Logout");
  }

}
