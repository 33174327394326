import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './features/accounts/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'unauth/login', pathMatch: 'full' },
  { path: 'unauth/login', component: LoginComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
