import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { Md5 } from 'ts-md5';
import { AuthService } from './auth.service';
import { SignOutParams, Demchqmobile, TransferOrder, GetHistoRequestType, Client, UpdatePasswordReqType, Compte, AccountHistory } from './model';
import { SoapService } from './soap.service';
import { SOAPClientParameters } from './SOAPClientParameters';
import { ResponsePolicy } from './STATUS';
import { TrameUtilService } from './trame-util.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public modul = '6';

  constructor(private soapService: SoapService,
    private authService: AuthService,
    private trameUtil: TrameUtilService) { }

  public login(username: string, pwd: string, codebanque: string): Observable<ResponsePolicy> {
    try {
      let token = this.authService.getToken();
    if (typeof token === 'undefined' || token == null) {
      const uuid = UUID.UUID();
      this.authService.setToken(uuid);
      token = this.authService.getToken();
    }
    const hashvalue = Md5.hashStr(pwd);
    return this.soapService.post<ResponsePolicy>('MobileLogin', new SOAPClientParameters()
      .add('username', username)
      .add('password', hashvalue.toString().toUpperCase())
      .add('codebanque', codebanque), true);
    } catch (error) {
    }
  }

  public NewCheckbookReq(params: Demchqmobile): Observable<string | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<string | ResponsePolicy>('AddDemchqmobile', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetCheckbookReq(numcpt: string): Observable<Array<Demchqmobile> | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity({ Key: 'Numcpt', Value: numcpt });
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<Array<Demchqmobile> | ResponsePolicy>('GetDemchqmobileByKeyValue', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetTransferOrders(numcpt: string): Observable<ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity({ Key: 'Num', Value: numcpt });
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<ResponsePolicy>('GetTransferOrdersByKeyValue', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public NewTransferOrder(params: TransferOrder): Observable<ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<ResponsePolicy>('AddTransferOrder', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetTransactionHist(params: GetHistoRequestType): Observable<AccountHistory | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<AccountHistory | ResponsePolicy>('GetAccountHistory', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetClientAccounts(numcli: string, codage: string): Observable<Array<Compte>> {
    try {
      const trameD = this.trameUtil.GetTrameForTable([numcli, codage]);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<Array<Compte>>('GetClientAccountById', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetAccountBalance(numcli: string, dateJr: string): Observable<string> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity({'Num': numcli, 'DateJour': dateJr});
      console.log('-------Account Balance------');
      console.log(trameD);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<string>('GetSolde', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public CreatePIN(params: Client): Observable<boolean | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<boolean | ResponsePolicy>('UpdateClient', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public CheckPIN(numcli: string, pin: string, codage: string): Observable<boolean | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForTable([numcli, codage, pin]);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<boolean | ResponsePolicy>('CheckClientPIN', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public UpdateUsername(username: string, numcli: string, codage: string): Observable<boolean | ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForTable([username, numcli, codage]);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<boolean | ResponsePolicy>('UpdateClientUserName', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public UpdatePassword(params: UpdatePasswordReqType): Observable<ResponsePolicy> {
    try {
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<ResponsePolicy>('UpdateClientPassword', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public GetLastNTransactions(numcpt: string, qty: number): Observable<AccountHistory> {
    try {
      const trameD = this.trameUtil.GetTrameForTable([numcpt, qty.toString()]);
      console.log('-------LastTRans------');
      console.log(trameD);
      const trame = this.trameUtil.encrypt(trameD);
      return this.soapService.post<AccountHistory>('GetAccountHistoryMobile', new SOAPClientParameters()
        .add('trame', trame));
    } catch (error) {
    }
  }

  public logout(params: SignOutParams): Observable<ResponsePolicy> {
    try {
      // console.log(params);
      const trameD = this.trameUtil.GetTrameForEntity(params);
      const trame = this.trameUtil.encrypt(trameD);
      // console.log(trame);
      return this.soapService.post<ResponsePolicy>('AlphaBDecnx', new SOAPClientParameters()
        .add('trame', trame));

    } catch (error) {
    }
  }
}
