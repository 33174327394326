export enum STATUS {
  OK = 200,
  SERVER_ERROR = 500,
  UNAUTHORISED = 401,
  NOT_FOUND = 404,
  BAD_REQUEST = 400,
  FORBIDEN = 403,
  CONFLICT = 409
}

export class ResponsePolicy {
    Data: any;
    Error: string;
    Status: number;
}
