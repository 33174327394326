import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GateWayAccountComponent } from './gate-way-account/gate-way-account.component';
import { WayInTwoComponent } from './way-in-two/way-in-two.component';
import { CheckbookRequestComponent } from './checkbook-request/checkbook-request.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PINComponent } from './pin/pin.component';
import { SelectAccountComponent } from './select-account/select-account.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TransfertOrderComponent } from './transfert-order/transfert-order.component';
import { UpdateUsernameComponent } from './update-username/update-username.component';
import { ChangePassComponent } from './change-pass/change-pass.component';

const routes: Routes = [
  { path: 'gate-way-acc', component: GateWayAccountComponent, children: [
    { path: 'choose/account', component: SelectAccountComponent}
  ]},
  { path: 'auth', component: WayInTwoComponent, children: [
    { path: 'dashboard', component: DashboardComponent},
    { path: 'pin/:id', component: PINComponent},
    { path: 'transfer-order', component: TransfertOrderComponent},
    { path: 'checkbook-req', component: CheckbookRequestComponent},
    { path: 'trans-hist', component: TransactionHistoryComponent},
    { path: 'update-username', component: UpdateUsernameComponent},
    { path: 'update-pass', component: ChangePassComponent}
  ]}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ]
})
export class AccountsRouterModule { }
