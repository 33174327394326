import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private _as: AuthService, private _router: Router) { }

  ngOnInit() {
  }

  logout(): void {
    const secret = this._as.getMifi().secret;
    this._as.clearTokens();
    this._router.navigate(['/unauth/login'], { queryParams: { secret: secret } });
    setTimeout(() => {
      window.location.reload();
    }, 100);
    console.log("Logout");
  }

}
